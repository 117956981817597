<template>
  <v-main id="app-main">
    <router-view />
    <core-footer />
  </v-main>
</template>

<script>
  export default {
    name: 'CoreView',
    components: {
      CoreFooter: () => import('@/views/components/core/Footer')
    }
  };
</script>
